/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

export type AsProp<C extends React.ElementType> = {
  as?: C;
};

export type Ref<C extends React.ElementType> = React.ComponentPropsWithRef<C>['ref'];

export type Props<C extends React.ElementType, P = NoProps> = OverrideProps<
  React.ComponentPropsWithoutRef<C>,
  P
> &
  AsProp<C>;

export type PropsWithRef<C extends React.ElementType, P = NoProps> = Props<C, P> & {
  ref?: Ref<C>;
};

export type Component<D extends React.ElementType, P = NoProps> = <C extends React.ElementType = D>(
  props: Props<C, P>
) => React.ReactNode;

export type ForwardRefComponent<D extends React.ElementType, P = NoProps> = <
  C extends React.ElementType = D
>(
  props: PropsWithRef<C, P>
) => React.ReactNode;

/**
 * Remove properties `K` from `T`.
 * Distributive for union types.
 */
export type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

export type OverrideProps<T, U> = DistributiveOmit<T, keyof U> & U;

export type DefaultPropsMap = {
  [P in keyof React.ReactHTML]?: React.ComponentPropsWithoutRef<P>;
};
