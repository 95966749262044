/* eslint-disable @typescript-eslint/no-explicit-any */

import { EntriesFragment } from '@/__generated__/graphql';
import { RawCategories } from '@/lib/parsers/categories';
import {
  parseBackgroundOpacity,
  parseBlockPosition,
  parseBlockWidth,
  parseDocument,
  parseDocuments,
  parseImageWidth,
  parseLinkField,
} from '@/lib/parsers/common';
// import { parseImage } from '@/lib/parsers/images';

import {
  parseSeoJsonLd,
  parseSeoMetaLinks,
  parseSeoMetaTags,
  parseSeoScripts,
  parseSeoSiteVars,
  parseSeoTitle,
} from '@/lib/parsers/seo/seo';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import {
  createPropSanitiser,
  SanitiserTypes,
  toId,
  toString,
  toStringOrNull,
  trimSlashes,
  Typename,
} from '@liquorice/allsorts-craftcms-nextjs';
import { RawBlocks } from '../parsers/blocks';
import { RawGlobals } from '../parsers/globals';
import { parseImage } from '../parsers/images';
import { parseFormieForm } from '../parsers/form';

/**
 * Union of all Typed Elements that may be passed to the sanitiser
 */
type RawElements = RawCategories | RawBlocks | EntriesFragment | RawGlobals;

/**
 * __typename of top level Elements
 */
type ElementTypename = Typename<RawElements>;

// ----------------------------------------------------------------------------------------------------
// --- Define the callbacks ---

export const sanitiseAnything = createPropSanitiser((): RawElements | null => null, {
  // Strings
  pageTitle: toStringOrNull,
  title: toStringOrNull,
  heading: toStringOrNull,
  slug: toString,
  label: toStringOrNull,
  caption: toStringOrNull,
  firstName: toStringOrNull,
  lastName: toStringOrNull,
  videoUrl: toStringOrNull,
  externalUrl: toStringOrNull,

  // HTML
  summary: cleanHtml,
  content: cleanHtml,
  largeContent: cleanHtml,
  smallContent: cleanHtml,
  subMsg: cleanHtml,

  // Special
  id: toId,
  form: parseFormieForm,
  linkField: parseLinkField,
  uri: (value?: string | null) => (value ? `/${trimSlashes(value)}/` : ''),
  backgroundColorOpacity: parseBackgroundOpacity,
  imageWidth: parseImageWidth,
  blockWidth: parseBlockWidth,
  blockPosition: parseBlockPosition,

  // Media
  logo: parseImage,
  entryImage: parseImage,
  imageSingle: parseImage,
  imageMultiple: parseImage,
  videoTextTracksSingle: parseDocument,
  documentMultiple: parseDocuments,

  // SEO
  seoJson: 'sanitiseSingle',
  metaLinkContainer: parseSeoMetaLinks,
  metaTagContainer: parseSeoMetaTags,
  metaTitleContainer: parseSeoTitle,
  metaScriptContainer: parseSeoScripts,
  metaJsonLdContainer: parseSeoJsonLd,
  metaSiteVarsContainer: parseSeoSiteVars,

  /**
   * Recursively sanitise child elements
   */
  blocks: 'sanitise',
  children: 'sanitise',
  socialLinks: 'sanitiseSingle',
  entrySingle: 'sanitiseSingle',
  profileMultiple: 'sanitise',
  jobPositionMultiple: 'sanitise',
  collaboratorMultiple: 'sanitise',
  instagramPostMultiple: 'sanitise',
  articleMultiple: 'sanitise',
  articleSingle: 'sanitiseSingle',
  organisations: 'sanitise',

  // Categories
  contentTypeCategorySingle: 'sanitiseSingle',
});

// ----------------------------------------------------------------------------------------------------
// --- Extracted sanitised types ---

type SanitiserReturnMap = SanitiserTypes<typeof sanitiseAnything, 'ReturnMap'>;

export type SanitisedElement<T extends ElementTypename = ElementTypename> = SanitiserReturnMap[T];

// ----------------------------------------------------------------------------------------------------
// --- Type guards ---

export const isSanitisedElement = <T extends ElementTypename>(
  x: any,
  typename: T
): x is SanitisedElement<T> => {
  return !!x && x.__typename === typename;
};
