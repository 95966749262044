import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fpalette.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2B2TzWrCUBCF93mKgbtpF5b8aBKnK7XmPaJt1Jh4NW1tofjuZa5Q5iAMdNVN71nl%2B5JDciA8eP9GXxHRaNRM%2BtZ3MZOLw3lUNGFyyUyiacrk0lyiacbksnA0HTO5IpFoOmFyT5VE05zJLQuJpoXQSqJpyeSqQqLpVOhComktNBxNV4HOxvjF60AXWTrX9DnQZTmHhpdAr5tdIr7ZtGE618Pdz2vc64c3KGOQW5QlyB3KBGSLMge5R5mC7FCuQPbW2x5QrkH62ycv0cP1Yvif6zdzvVpzxdZctTVXYs1VWnOl1lz5X8%2BFP%2BMR72yg5oRyo2verZqtVbPTNWerprVq9rrmw6rprJpe13xaNQerxkvNN53OjCBABgAA%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5WTS26DMBCG9znFLBM1WH5hwDlDNr1ARcAhbnmkQBq1Ve5e2XlhcCpl%2B83Mp38GI9um6eF3BhAEb0RHdKMJlrBWrc51WsOrKg5l2i49pEvrLuhUq7crZ5z4xh8102ea2aB5rXJ9qJ7JxSUQREIXhgZSlwnDmMsiw7jLYstGwsQKw1ZVLk8lZGVa7ecUmeISsrTM5hhxIkQcGQQvwBHj%2BOu4WEKIhJUsXMvGayGIiIGFoiiKrYUjjyO7OYYGxhgj9GIgiMWJNbDpfC6BTtZTVyn5JxhGIuFWS6farTnc9G7FXXyOd7vcQBtSYrXEt%2B%2Fuwc3EMBpBERPnjVHkkegHn%2FXdPipP4UPCNaJbKG3BN1JJ4Bi7rJYQjlkjIcAI0%2FH83phxPMafEvBqdpptmvzb%2Funbpu6DTv8oN3upaxXslC52%2Ff1hl6rvVRt0%2BzTTdWFNF8Hx0mkjn%2F4A0n%2BzCUgEAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fgrid.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0tXzFhY2ViNHEwOiAxMjsKICAtLV8xYWNlYjRxMTogMTZweDsKICAtLV8xYWNlYjRxMjogMzJweDsKICAtLV8xYWNlYjRxMzogMzJweDsKfQ%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fpartials%2Fspacing.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0ta2RtdnFpMDogMDsKICAtLWtkbXZxaTE6IDFweDsKICAtLWtkbXZxaTI6IDJweDsKICAtLWtkbXZxaTM6IHZhcigtLV8xYWNlYjRxMyk7CiAgLS1rZG12cWk0OiB2YXIoLS1fMWFjZWI0cTEpOwogIC0ta2RtdnFpNTogNDBweDsKICAtLWtkbXZxaTY6IDAuMjVyZW07CiAgLS1rZG12cWk3OiAwLjVyZW07CiAgLS1rZG12cWk4OiAwLjc1cmVtOwogIC0ta2RtdnFpOTogMXJlbTsKICAtLWtkbXZxaWE6IDEuMjVyZW07CiAgLS1rZG12cWliOiAxLjVyZW07CiAgLS1rZG12cWljOiAycmVtOwogIC0ta2RtdnFpZDogMi41cmVtOwogIC0ta2RtdnFpZTogY2xhbXAoMi41cmVtLCBjYWxjKDIuMTY2Njg3NXJlbSArIDAuNjk0dncpLCAzcmVtKTsKICAtLWtkbXZxaWY6IGNsYW1wKDNyZW0sIGNhbGMoMi42NjY2ODc1cmVtICsgMC42OTR2dyksIDMuNXJlbSk7CiAgLS1rZG12cWlnOiBjbGFtcCgzLjVyZW0sIGNhbGMoMi44MzMzMTI1cmVtICsgMS4zODl2dyksIDQuNXJlbSk7CiAgLS1rZG12cWloOiBjbGFtcCgzLjVyZW0sIGNhbGMoMi41cmVtICsgMi4wODN2dyksIDVyZW0pOwogIC0ta2RtdnFpaTogY2xhbXAoNXJlbSwgY2FsYygzLjMzMzMxMjVyZW0gKyAzLjQ3MnZ3KSwgNy41cmVtKTsKfQ%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ftheme%2Fvars.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42U226DMAyG7%2FcUvmylUsUJR%2B9hJk7tGC1hgXZ00959Cmo7nGjqJBDiy%2B%2FfjsEho%2FUIX08AQaDN1LexIEAhxPMCIUHGiSRAxZEiYO8hQZTE%2FbRkEUESp5zFBFkiOEsIEIUDUwLMpAMzAslJTjDoQ1MtWeHUVhKIbWTq4xJWFqaJi2sC3KJ08Y5AehZ7gnNuVkHwgk0iiwbb9XL5lQCdiMYm9czfbE7lldL68Qc3Y8MyHv2Izmb0rLXrUzOf3u7Wb8K7rdNvmSEQ%2FTTfcT%2BB2Rf5Smzgem1RMe%2FhV43isXxcyP%2FhfqJ7HfIW54bIaL25m4Z%2FiDBivuf5ww1L9EGzjLHJl10sUgx9WhQyhGL%2BRzlDgiIv273Rp64KSn3QZgPXR6FNVZsbnAuBOh%2FqoOkCfRqZjyTQfV424%2BWBUBGMJu%2BGnTbHB9LQmTGMyPaSoZjAmX60o%2B6cEpgSqHnOv38A3AnRG58EAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fui%2FTxt%2FTxt.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA9WX3ZKiMBCF732KvtypAkuMPzPsw0wFCCSaHzbEGd2tffctcEYbxw1SypTemq85h9jpHMZu53g%2BmcGfEUBqpLExvFH7Iwz3C5MAhObMCvf0cwSQG%2B3CdyYK7trcFHN%2FR%2BP9r%2FPmsWeKXiOxnCYiUsen5lQJuTsFJkegEr%2FZ6XLSLEuhWcjPCsz2AHOO2bAqaSp0ccoYbHkxtOX05paXQ1vOuizPsZ3noe2wXnZehraTd9lZYDt0aDtFLzvJ0c55tcivJrvUllgtvVJt3Ustu3qrO%2Bysuuw8YzvsypcXvV4%2B71LraCN%2ByZg6qBVDdzW9%2BdQUjeXDPffJ5nO1MpJhcuUjc0yufWSBSekjOSaVjxSY1D5yhUnjI9eYLH2kxOQvH6kwaX2kxmTlI1v%2FpmtIRW0hdJgY54yKYTJeMIWgTQM5tnUhlaLQMaRMO2YR8vYFkSx3CHj%2FAti6HRGxvfoskF5nb9fo%2BTu%2FvEzyusH7sS3OUl3lxqoYNmXJbEorhuwSoDhsfqTGQ3XGUmOpE0bHsNEZs7Voq5w7JQNITLa7ZKCiwspZo4sAOu880q7byADM%2FsCWNMuELsK6KWKIxouUt1ApLulDAjx6vGRMgE8fLxwT4OS%2B8jEBPruviEyAD%2F6l1i8lE%2BCDf4j1C8oEnNAXTZybpWUClaJSfmtmJieD9R6CMwFJbfG98ZnAtnPjb5qgCXBGs5p%2BvChd32cB8GkAnATAZwHwefB5gv9zF%2F4DY7NZJHkSAAA%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var root = {fn:_7a468({defaultClassName:'tythf04',variantClassNames:{variant:{h1:'tythf05',h2:'tythf06',h3:'tythf07',h4:'tythf08',h5:'tythf09',h6:'tythf0a',tiny:'tythf0b',small:'tythf0c',body:'tythf0d',large:'tythf0e',xl:'tythf0f',headline:'tythf0g',inherit:'tythf0h'},color:{error:'tythf0i',full:'tythf0j',fullContrast:'tythf0k',threeQuarters:'tythf0l',threeQuartersContrast:'tythf0m',half:'tythf0n',halfContrast:'tythf0o',accent:'tythf0p',accentContrast:'tythf0q',accentLight:'tythf0r',accentLightContrast:'tythf0s'},autoMargin:{true:'tythf0t'},textAlign:{center:'tythf0u',left:'tythf0v',right:'tythf0w'},medium:{true:'tythf0x'},allCaps:{true:'tythf0y'}},defaultVariants:{},compoundVariants:[]}),variantKeys:['variant','color','autoMargin','textAlign','medium','allCaps']};
export var txtVars = {color:'var(--tythf00)',display:'var(--tythf01)',fontWeight:'var(--tythf02)'};
export var typographyClasses = {h1:{fontWeight:'var(--_1i72bi1m)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1b)',lineHeight:'var(--_1i72bi14)',letterSpacing:'var(--_1i72bi1o)'},h2:{fontWeight:'var(--_1i72bi1m)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1c)',lineHeight:'var(--_1i72bi14)',letterSpacing:'var(--_1i72bi1o)'},h3:{fontWeight:'var(--_1i72bi1m)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1d)',lineHeight:'var(--_1i72bi15)'},h4:{fontWeight:'var(--_1i72bi1m)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1e)',lineHeight:'var(--_1i72bi15)'},h5:{fontWeight:'var(--_1i72bi1m)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1f)',lineHeight:'var(--_1i72bi16)'},h6:{fontWeight:'var(--_1i72bi1m)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1g)',lineHeight:'var(--_1i72bi16)'},tiny:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1l)',lineHeight:'var(--_1i72bi17)'},small:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1k)',lineHeight:'var(--_1i72bi17)'},body:{fontWeight:'var(--_1i72bi1m)',fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1j)',lineHeight:'var(--_1i72bi18)'},large:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1i)',lineHeight:'var(--_1i72bi17)'},xl:{fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1h)',lineHeight:'var(--_1i72bi14)'},headline:{fontWeight:'var(--_1i72bi1m)',fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1a)',lineHeight:'var(--_1i72bi14)',letterSpacing:'var(--_1i72bi1o)'},inherit:{}};
export var withHtml = 'tythf03';