import { root } from './Flex.css';

import { createUseRecipeHook, PropsWithClassName, useSprinkles } from '@/theme';
import { RecipeVariants } from '@/theme/utils/createRecipeObj';
import { Sprinkles } from '@/theme/sprinkles.css';

import classNames from 'classnames';

export type FlexStyleProps = RecipeVariants<typeof root.fn> &
  Pick<
    Sprinkles,
    'display' | 'direction' | 'spacing' | 'rowGap' | 'columnGap' | 'alignItems' | 'justifyContent'
  > & {
    wrap?: Sprinkles['wrap'] | boolean;
    // inline?: boolean
  };

const useFlexRecipe = createUseRecipeHook(root);

export const useFlexStyle = <P extends PropsWithClassName<FlexStyleProps>>({
  direction = 'column',
  spacing,
  rowGap,
  columnGap,
  wrap,
  display = 'flex',
  alignItems,
  justifyContent,
  ...props
}: P) => {
  const className = classNames(
    useSprinkles({
      display,
      direction,
      spacing,
      rowGap,
      columnGap,
      wrap: typeof wrap === 'boolean' ? (wrap ? 'wrap' : 'nowrap') : wrap,
      alignItems,
      justifyContent,
    }),
    props.className
  );

  return useFlexRecipe({ ...props, className });
};
