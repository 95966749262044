import * as btnStyles from './Btn.css';

import { RecipeVariants } from '@/theme/utils/createRecipeObj';
import { createUseRecipeHook, PropsWithClassName } from '@/theme/utils/createUseRecipeHook';

const useBtnStyleRecipe = createUseRecipeHook(btnStyles.root);

export const useBtnStyle = <P extends PropsWithClassName<BtnStyleProps>>(props: P) => {
  return useBtnStyleRecipe(props);
};

export type BtnStyleProps = RecipeVariants<typeof btnStyles.root>;

export { btnStyles };
