import {
  deleteUndefined,
  firstNonNullable,
  toString,
  toStringOrNull,
} from '@liquorice/allsorts-craftcms-nextjs';
// import { DocumentAsset } from '.';

export interface Link extends Pick<IntrinsicProps<'a'>, 'target' | 'title'> {
  href: string;
  text?: string;
  children?: string;
  download?: boolean;
  file?: any; // TODO - DocumentAsset;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseLinkField = (data: MaybeArrayOf<any>): Link | null => {
  const link = firstNonNullable(data);

  if (!link || !link.url) return null;

  const { target, type, text, title, url: href } = link;

  const download = type === 'asset';
  const defaultTarget =
    type && ['asset', 'mail', 'url', 'tel'].includes(type) ? '_blank' : undefined;

  const field: Link = {
    href: toString(href),
    target: toStringOrNull(target) ?? defaultTarget,
    children: toStringOrNull(text) ?? undefined,
    title: toStringOrNull(title) ?? undefined,
    download,
  };

  return deleteUndefined(field);
};
