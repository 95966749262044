import { RecipeObj, RecipeVariants, VariantGroups } from './createRecipeObj';
import { createUseRecipeHook } from './createUseRecipeHook';

import React from 'react';

type PropsWithClassName<P = NoProps> = P & { className?: string };

interface WithRecipe {
  <P extends PropsWithClassName, U, Recipe extends RecipeObj<VariantGroups>>(
    Component: (props: P) => U,
    recipe: Recipe
  ): (props: OverrideProps<P, RecipeVariants<Recipe>>) => U;
  //
  <P extends PropsWithClassName, T, U, Recipe extends RecipeObj<VariantGroups>>(
    Component: React.ForwardRefExoticComponent<React.PropsWithoutRef<P> & React.RefAttributes<T>>,
    recipe: Recipe
  ): (props: OverrideProps<P, RecipeVariants<Recipe>>) => U;
}

/**
 * A decorator to apply {@link RecipeObj} props & style to an existing component
 * */
export const withRecipe: WithRecipe = <
  T,
  P extends PropsWithClassName,
  Recipe extends RecipeObj<VariantGroups>
>(
  Component: (props: P) => React.ReactNode,
  // | ((props: P, ref: React.RefObject<T>) => React.ReactNode | null),
  recipe: Recipe
) => {
  // Create the useRecipe hook
  const useRecipe = createUseRecipeHook(recipe);

  const MyFunction = (
    props: OverrideProps<P, RecipeVariants<Recipe>>,
    ref?: React.ForwardedRef<T>
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <Component ref={ref} {...(useRecipe(props) as any)} />;
  };

  // Assign the original displayName
  MyFunction.displayName =
    'displayName' in Component && typeof Component.displayName === 'string'
      ? Component.displayName || Component.name
      : Component.name;

  // Forward the ref
  return React.forwardRef(MyFunction);
};
