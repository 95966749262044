import { TxtVariants } from './Txt.css';

import React from 'react';

type TxtVariant = NonNullable<TxtVariants['variant']>;

type VariantMap<Variants extends string> = Record<Variants, React.ElementType>;

/**
 * @todo Move somewhere more useful
 */
const createVariantMapping = <Variants extends string>(map: Partial<VariantMap<Variants>>) => map;

const txtVariantMap = createVariantMapping<TxtVariant>({
  tiny: 'p',
  small: 'span',
  body: 'p',
  large: 'span',
  headline: 'h1',
  xl: 'span',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
});

export const useTxtVariantMap = (
  value: TxtVariant | undefined,
  defaultComponent: React.ElementType
) => {
  return (value && txtVariantMap[value] ? txtVariantMap[value] : defaultComponent) || 'span';
};
