import { GlobalsQueryVariables } from '@/__generated__/graphql';
import { GLOBALS_QUERY } from '@/gql/queries/globals.gql';
import { useQuery } from '@apollo/client';
import { AppGlobal, GlobalTypename, parseGlobals } from '../parsers/globals';
import { firstNonNullable } from '@liquorice/allsorts-craftcms-nextjs';

export const useGlobal = <TName extends GlobalTypename>(
  variables: GlobalsQueryVariables = {},
  typeNames: TName
) => {
  const { data, loading, error } = useQuery(GLOBALS_QUERY, {
    variables,
  });
  if (error) return null && console.log(`An error occurred with the query: ${error.message}`);

  const result = parseGlobals(data?.globalSets);

  const global = firstNonNullable(result) as AppGlobal<typeof typeNames>;

  return {
    ...global,
    loading,
    error,
  };
};
