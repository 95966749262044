import { values } from '@liquorice/allsorts-craftcms-nextjs';
import {
  FieldValue,
  FieldValueComplex,
  FieldValueMulti,
  FieldValueSingle,
  FormValues,
} from './formTypes';

// ---- Type Guards ----

export const isFieldValueSingle = (x?: FieldValue | FormValues): x is FieldValueSingle => {
  return !x || typeof x === 'string';
};

export const isFieldValueMulti = (x: FieldValue | FormValues): x is FieldValueMulti => {
  if (isFieldValueSingle(x)) return false;
  const nonBoolValues = values(x).filter((x) => typeof x !== 'boolean');
  return nonBoolValues.length ? false : true;
};

export const isFieldValueComplex = (x: FieldValue | FormValues): x is FieldValueComplex => {
  if (isFieldValueSingle(x)) return false;
  if (isFieldValueMulti(x)) return false;
  return true;
};
