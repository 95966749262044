import {
  SeoJsonLdFragment,
  SeoMetaLinksFragment,
  SeoMetaTagsFragment,
  SeoScriptsFragment,
  SeoSiteVarsFragment,
} from '@/__generated__/graphql';
import { recursiveTrimNonTruthy } from './recursiveTrimNonTruthy';

import { Metadata } from 'next';
import { OpenGraph } from 'next/dist/lib/metadata/types/opengraph-types';
import { ScriptProps } from 'next/script';
import { ComponentProps } from 'react';

type SeomaticMetaTag = {
  content: string;
  property?: string;
  name?: string;
};

type SeomaticLinkTag = {
  href: string;
  rel?: string;
  type?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isSeomaticMetaTag = (item: any): item is SeomaticMetaTag => {
  return item?.content && (item?.property || item?.name);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isSeomaticLinkTag = (item: any): item is SeomaticLinkTag => {
  return item?.href && (item?.rel || item?.type);
};

// ------------------------------------------------------------------------------------------------
// ---- Types ----

type SeoContainers =
  | SeoMetaLinksFragment
  | SeoMetaTagsFragment
  | SeoScriptsFragment
  | SeoJsonLdFragment
  | SeoSiteVarsFragment;

export const parseSeoContainerJson = <T extends SeoContainers | string>(maybeData: Maybe<T>) => {
  try {
    const parsedValue = maybeData && typeof maybeData === 'string' ? JSON.parse(maybeData) : null;
    return recursiveTrimNonTruthy(parsedValue);
  } catch (error) {
    console.error(error);
    return null;
  }
};

type MaybeSeoData = SeoContainers | string | null | undefined;

export const parseSeoMetaLinks = (maybeData: MaybeSeoData): Metadata => {
  const data = parseSeoContainerJson(maybeData);
  const linkSource = Object.values(data).filter(isSeomaticLinkTag);
  const tags = linkSource.reduce((result, item) => {
    const { href, rel } = item;
    if (rel) result[rel] = href;
    return result;
  }, {} as Record<string, string>);

  return { alternates: tags };
};

export const parseSeoTitle = (maybeData: MaybeSeoData): string | null => {
  const data = parseSeoContainerJson(maybeData);
  return data?.title?.title || null;
};

export const parseSeoMetaTags = (maybeData: MaybeSeoData): Metadata => {
  const metadata: Metadata = {
    openGraph: {},
  };

  const openGraph: OpenGraph = {};
  const openGraphImage: Extract<OpenGraph['images'], Record<string, unknown>> = {
    url: '',
  };
  const twitter: NonNullable<Metadata['twitter']> = {};
  const twitterImage: Extract<
    NonNullable<Metadata['twitter']>['images'],
    Record<string, unknown>
  > = {
    url: '',
  };

  const data = parseSeoContainerJson(maybeData);

  const tagSource = Object.values(data).filter(isSeomaticMetaTag);
  const other = tagSource.reduce((result, item) => {
    const { content: rawContent, property, name = property } = item;

    const content = rawContent;

    switch (name) {
      case 'generator':
        break;
      case 'og:image':
        openGraphImage.url = content;
        break;
      case 'og:image:width':
        openGraphImage.width = content;
        break;
      case 'og:image:height':
        openGraphImage.height = content;
        break;
      case 'og:image:alt':
        openGraphImage.alt = content;
        break;
      case 'og:title':
        openGraph.title = content;
        break;
      case 'og:description':
        openGraph.description = content;
        break;
      case 'og:url':
        openGraph.url = content;
        break;
      case 'og:site_name':
        openGraph.siteName = content;
        break;
      case 'og:locale':
        openGraph.locale = content;
        break;
      case 'twitter:card':
        // twitter = content;
        break;
      case 'twitter:creator':
        twitter.creator = content;
        break;
      case 'twitter:title':
        twitter.title = content;
        break;
      case 'twitter:description':
        twitter.description = content;
        break;
      case 'twitter:image':
        twitterImage.url = content;
      case 'twitter:image:width':
        twitterImage.width = content;
      case 'twitter:image:height':
        twitterImage.height = content;
      case 'twitter:image:alt':
        twitterImage.alt = content;
        break;

      // Allow the following explicitly
      case 'og:type':
      default:
        if (name) result[name] = content;
        break;
    }

    return result;
  }, {} as Record<string, string>);

  return {
    ...metadata,
    openGraph: {
      ...openGraph,
      images: [openGraphImage],
    },
    twitter: {
      ...twitter,
      images: [twitterImage],
    },
    other,
  };
};

export const parseSeoScripts = (maybeData: MaybeSeoData) => {
  const data = parseSeoContainerJson(maybeData);

  const scripts = Object.entries(data).reduce(
    (result, [key, item]) => {
      const { script, bodyScript } = item as {
        script?: string;
        bodyScript?: string;
      };

      script &&
        result.scripts.push({
          id: `${key}-script`,
          type: 'text/javascript',
          dangerouslySetInnerHTML: {
            __html: script,
          },
        });

      bodyScript &&
        result.bodyScripts.push({
          id: `${key}-bodyScript`,

          dangerouslySetInnerHTML: {
            __html: bodyScript,
          },
        });

      return result;
    },
    {
      scripts: [] as ScriptProps[],
      bodyScripts: [] as ComponentProps<'span'>[],
    }
  );

  return scripts;
};

export const parseSeoJsonLd = (maybeData: MaybeSeoData): ScriptProps => {
  const data = parseSeoContainerJson(maybeData);
  const scriptContents = JSON.stringify({
    '@context': 'http://schema.org',
    '@graph': Object.values(data),
  });

  return {
    id: 'seo-json-ld',
    type: 'application/ld+json',
    dangerouslySetInnerHTML: {
      __html: scriptContents,
    },
  };
};

export const parseSeoSiteVars = (maybeData: MaybeSeoData) => {
  const data = parseSeoContainerJson(maybeData);
  return data;
};
