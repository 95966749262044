import Icon, { IconName, IconProps } from '@/components/Icon';

import React from 'react';

export type BtnIconsProps = {
  endIcon?: IconName | React.ReactElement;
  EndIconProps?: IconProps;
  startIcon?: IconName | React.ReactElement;
  StartIconProps?: IconProps;
};

type UseBtnIconsProps = React.PropsWithChildren<
  {
    className?: string;
  } & BtnIconsProps
>;

export const useBtnIcons = <P extends UseBtnIconsProps>(props: P) => {
  const {
    children: label,
    endIcon: maybeEndIcon,
    EndIconProps,
    startIcon: maybeStartIcon,
    StartIconProps,
    ...rest
  } = props;

  const startIcon = maybeStartIcon ?? StartIconProps?.name;
  const endIcon = maybeEndIcon ?? EndIconProps?.name;

  const startIconEl =
    typeof startIcon === 'string' ? <Icon name={startIcon} {...StartIconProps} /> : startIcon;
  const endIconEl =
    typeof endIcon === 'string' ? <Icon name={endIcon} {...EndIconProps} /> : endIcon;

  const children = (
    <>
      {startIconEl}
      {label}
      {endIconEl}
    </>
  );

  return {
    ...rest,
    children,
  };
};
