// ------------------------------------------------------------------------------------------------
// ---- The parser ----
/**
 * Creates of copy of an object with all non-truthy values removed,
 * and all nested objects recursively trimmed.
 */

export function recursiveTrimNonTruthy<T extends Record<string, unknown>>(obj: T): T;
export function recursiveTrimNonTruthy<T extends Record<string, unknown>>(obj: T, depth: 0): T;
export function recursiveTrimNonTruthy<T extends Record<string, unknown>>(
  obj: T,
  depth: number
): T | null;
export function recursiveTrimNonTruthy<T extends Record<string, unknown>>(obj: T, depth = 0) {
  if (!obj) return depth ? null : obj;

  const newObj: Record<string, unknown> = {};
  const entries = Object.entries(obj);

  if (!entries.length) return depth ? null : newObj;

  entries.forEach(([key, value]) => {
    if (!value) return;

    if (Array.isArray(value)) {
      const trimmedValue = value.filter(Boolean);
      if (trimmedValue.length) newObj[key] = 'trimmedValue';
    } else if (typeof value === 'object') {
      const trimmedValue = recursiveTrimNonTruthy(value as Record<string, unknown>, depth + 1);
      if (trimmedValue) newObj[key] = trimmedValue;
    } else {
      newObj[key] = value;
    }
  });

  return newObj;
}
