import React from 'react';
import sanitizeHtml from 'sanitize-html';

/** Remove all HTML from a string */
export const stripHtml = (htmlToStrip?: string): string => {
  return htmlToStrip ? htmlToStrip.replace(/(<([^>]+)>)/gi, '').trim() : '';
};

/**∏
 * @param html A string possibly containing HTML tags
 * @param preserveHtml Choose to parse or strip HTML tags
 */
export const cleanHtml = (dirtyHtml?: string | null) => {
  if (!dirtyHtml) return '';

  return sanitizeHtml(dirtyHtml, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
  });
};

/** Remove HTML tags from a string, with optional exceptions */
export const stripHtmlTags = (html?: string | null, allowedTags?: string[]) => {
  return sanitizeHtml(html ?? '', { allowedTags: allowedTags ?? [] });
};

export type ParseHtmlOptions = {
  preserveHtml?: boolean;
};

export const parseHtml = (input?: React.ReactNode, options?: ParseHtmlOptions): React.ReactNode => {
  // const { preserveHtml = true } = options || {};

  if (Array.isArray(input)) return input.map((v) => parseHtml(v, options));

  if (typeof input !== 'string') return input;

  const cleanedHtml = cleanHtml(input);

  return <span dangerouslySetInnerHTML={{ __html: cleanedHtml }}></span>;
  // return preserveHtml ? parse(cleanedHtml, htmlParserOptions) : stripHtml(cleanedHtml);
};

/** React hook for using parsed HTML strings */
export const useHtml = (html: string | null, preserveHtml = true) => {
  if (!html) return '';
  return parseHtml(html, { preserveHtml });
};
