/* eslint-disable @typescript-eslint/no-explicit-any */
import { stripHtmlTags } from '@/lib/utils/htmlHelpers';
import { toStringOrNull } from '@liquorice/allsorts-craftcms-nextjs';

export type Alignment = 'left' | 'auto' | 'right';
export type TextAlignment = 'auto' | 'center' | 'left' | 'right';
export type PhoneNumber = string;
export type Opacity = 'full' | 'threeQuarters' | 'half' | undefined;
export type ImageWidth = 6 | 12;
export type BlockWidth = 'reduced' | 'full' | undefined;
export type Position = 'right' | 'left';

/**
 * Check and set the 'alignment' field
 */
export const parseTextAlignment = (maybeAlignment: any): TextAlignment => {
  switch (maybeAlignment) {
    case 'center':
      return 'center';
    case 'left':
      return 'left';
    case 'right':
      return 'right';
    default:
      return 'auto';
  }
};
/**
 * Check and set the 'alignment' field
 */
export const parseAlignment = (maybeAlignment: any): Alignment => {
  switch (maybeAlignment) {
    case 'left':
      return 'left';
    case 'right':
      return 'right';
    default:
      return 'auto';
  }
};

export const parseImageWidth = (maybeWidth: any): ImageWidth => {
  switch (maybeWidth) {
    case 'full':
      return 12;
    case 'half':
      return 6;
    default:
      return 12;
  }
};

export const parseBlockPosition = (maybePosition: any): Position => {
  switch (maybePosition) {
    case 'right':
      return 'right';
    case 'left':
      return 'left';
    default:
      return 'left';
  }
};

export const parseBlockWidth = (maybeWidth: any): BlockWidth => {
  switch (maybeWidth) {
    case 'reduced':
      return 'reduced';
    case 'full':
      return 'full';
    default:
      return undefined;
  }
};

export const parseBackgroundOpacity = (maybeOpacity: any): Opacity => {
  switch (maybeOpacity) {
    case 'full':
      return 'full';
    case 'threeQuarters':
      return 'threeQuarters';
    case 'half':
      return 'half';
    case 'auto':
      return undefined;
    default:
      return undefined;
  }
};

export const parsePhoneNumber = (maybePhoneNumber: Maybe<any>): PhoneNumber | null => {
  if (
    (maybePhoneNumber.number || maybePhoneNumber.formatForCountry) &&
    typeof (maybePhoneNumber.number || maybePhoneNumber.formatForCountry) === 'string'
  ) {
    const number = maybePhoneNumber.formatForCountry ?? maybePhoneNumber.number;

    return toStringOrNull(number);
  }
  return null;
};

/**
 * Replaces line breaks in a string with an HTML <br> tag.
 *
 * @param {string | undefined} text - The text to process.
 * @returns {string | undefined} The processed text.
 */
export const replaceLineBreaksWithBr = (text?: Maybe<string>) => {
  return text?.replace(/\n/g, '<br>');
};

export const sanitiseHeading = (maybeHeading?: Maybe<string>) => {
  return stripHtmlTags(replaceLineBreaksWithBr(maybeHeading), ['strong', 'br']);
};
