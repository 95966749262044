import { IMG_RATIO } from '@/lib/constants';

export const ratios = {
  square: `${IMG_RATIO.SQUARE * 100}%`,
  portrait: `${IMG_RATIO.PORTRAIT * 100}%`,
  landscape: `${IMG_RATIO.LANDSCAPE * 100}%`,
  landscapeTall: `${IMG_RATIO.LANDSCAPE_TALL * 100}%`,
  landscapeWide: `${IMG_RATIO.LANDSCAPE_WIDE * 100}%`,
  video: `${IMG_RATIO.VIDEO * 100}%`,
};

export type Ratio = keyof typeof ratios;
