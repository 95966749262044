import {
  isRecipeObj,
  RecipeObj,
  RecipeRuntimeFn,
  VariantGroups,
  VariantKeys,
  VariantSelection,
} from './createRecipeObj';

import { unpackProps } from '@liquorice/allsorts-craftcms-nextjs';
import classNames from 'classnames';

/**  */
export type PropsWithClassName<P> = P & { className?: string };

/** Component props with variants and className */
export type PropsWithVariants<Variants extends VariantGroups> = VariantSelection<Variants> & {
  className?: string;
};

/** A React hook which extracts recipe props and returns
 * the remaining props with an updated `className`
 */
export type UseRecipeHook<Variants, Key extends keyof Variants = keyof Variants> = <
  P extends Variants & { className?: string }
>(
  props: P
) => Omit<P, Key | 'as'> & { className?: string };

interface CreateUseRecipeHook {
  /**
   * Return a hook to extract properties listed in `keys`
   * from a {@link RecipeRuntimeFn} or {@link RecipeObj}
   */
  <Variants extends VariantGroups, Key extends keyof Variants>(
    recipeFn: RecipeRuntimeFn<Variants> | RecipeObj<Variants>,
    keys: Key[]
  ): UseRecipeHook<Exact<VariantSelection<Variants>>, Key>;

  /**
   * Return a hook to extract all properties from a {@link RecipeObj}
   */
  <Variants extends VariantGroups, Key extends keyof Variants>(
    recipeObject: RecipeObj<Variants>
  ): UseRecipeHook<Exact<VariantSelection<Variants>>, Key>;
}

/**
 * Create a React Hook to consume and apply Vanilla Extract recipe properties
 * from Component props.
 * Can be used in conjunction with {@link createRecipeObj} to avoid needing to re-list
 * the variant prop keys
 */
export const createUseRecipeHook: CreateUseRecipeHook = <
  Variants extends VariantGroups,
  Key extends VariantKeys<Variants>
>(
  recipe: RecipeRuntimeFn<Variants> | RecipeObj<Variants>,
  suppliedKeys?: Key[]
) => {
  const recipeFn = isRecipeObj(recipe) ? recipe.fn : recipe;

  const keys = suppliedKeys ?? ((isRecipeObj(recipe) ? recipe.variantKeys : []) as Key[]);

  const useRecipeHook = <P extends PropsWithVariants<Variants>>(props: P) => {
    const { unpacked, rest } = unpackProps(props, keys);
    const recipeClassName = recipeFn(unpacked as VariantSelection<Variants>);
    const className = classNames(props.className, recipeClassName);

    return {
      ...rest,
      className,
    } as Omit<P, Key> & { className: string };
  };

  return useRecipeHook as UseRecipeHook<Variants, Key>;
};
