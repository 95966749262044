import { gql } from '@/__generated__';

/**
 * Retrieve all global sets or a specific global set by handle
 */
export const GLOBALS_QUERY = gql(`
  query globals($handle: [String]) {
    globalSets(handle: $handle) {
      ...globals
    }
  }
`);
