'use client';

import { SvgIconProps, SvgIconTypeMap } from '@/components/ui';
import { SprinklesProps, useSprinklesProps } from '@/theme';
import Add from '@mui/icons-material/AddRounded';
import ArrowCircleRight from '@mui/icons-material/ArrowCircleRight';
import ArrowUpward from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownward from '@mui/icons-material/ArrowDownwardRounded';
import Remove from '@mui/icons-material/RemoveRounded';
import Event from '@mui/icons-material/Event';
import Share from '@mui/icons-material/Share';
import Newspaper from '@mui/icons-material/Newspaper';
import Video from '@mui/icons-material/VideoLibrary';
import Podcasts from '@mui/icons-material/Podcasts';
import Insight from '@mui/icons-material/Lightbulb';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';
import YouTube from '@mui/icons-material/YouTube';
import Twitter from '@mui/icons-material/Twitter';
import Error from '@mui/icons-material/Error';

import ChevronDown from '@/components/Assets/24px-chevron-down.svg';
import ChevronUp from '@/components/Assets/24px-chevron-up.svg';
import Unmute from '@/components/Assets/24px-video-sound-on.svg';
import Mute from '@/components/Assets/24px-video-sound-off.svg';
import Play from '@/components/Assets/24px-video-play.svg';
import Pause from '@/components/Assets/24px-video-pause.svg';
import Search from '@/components/Assets/24px-search.svg';
import Menu from '@/components/Assets/24px-hamburger-menu.svg';
import Download from '@/components/Assets/24px-download.svg';
import Close from '@/components/Assets/24px-close.svg';
import ArrowForward from '@/components/Assets/24px-carousel-arrow-right.svg';
import ArrowBack from '@/components/Assets/24px-carousel-arrow-left.svg';
import ArrowOutward from '@/components/Assets/24px-arrow-up-right.svg';

import { SvgIcon } from '@mui/material';
import * as style from './Icon.css';

const customIcons = {
  play: Play,
  arrowOutward: ArrowOutward,
  arrowRight: ArrowForward,
  arrowLeft: ArrowBack,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  menu: Menu,
  pause: Pause,
  mute: Mute,
  unmute: Unmute,
  search: Search,
  close: Close,
  download: Download,
};

const appIcons = {
  arrowUp: ArrowUpward,
  arrowDown: ArrowDownward,
  returnRight: ArrowCircleRight,
  news: Newspaper,
  insight: Insight,
  event: Event,
  podcast: Podcasts,
  video: Video,
  share: Share,
  linkedin: LinkedIn,
  facebook: Facebook,
  twitter: Twitter,
  youtube: YouTube,
  instagram: Instagram,
  minus: Remove,
  plus: Add,
  error: Error,
  ...customIcons,
} as const;

const icons = {
  ...appIcons,
  ...customIcons,
};

export type AppIcons = typeof appIcons;
export type AppIcon = AppIcons[AppIconName];
export type AppIconName = keyof AppIcons;

export type CustomIcons = typeof customIcons;
export type CustomIcon = CustomIcons[CustomIconName];
export type CustomIconName = keyof CustomIcons;

export type Icons = typeof icons;
export type Icon = Icons[IconNames];
export type IconNames = AppIconName | CustomIconName;
export type { IconNames as IconName };

export type IconProps<
  D extends React.ElementType = SvgIconTypeMap['defaultComponent'],
  P = NoProps
> = SvgIconProps<
  D,
  {
    name: IconNames;
    className?: string;
  } & SprinklesProps &
    P
>;
const Icon = ({ name, ...rest }: IconProps) => {
  const Component = icons[name];
  const props = useSprinklesProps(rest);

  const sx = {
    fontSize: style.iconVars.fontSize,
    ...props.sx,
  };

  // If name equals the key of any of the customIcons, render the custom icon
  if (name in customIcons)
    return (
      <SvgIcon fontSize="inherit" titleAccess={name} {...props} sx={sx}>
        <Component />
      </SvgIcon>
    );

  return <Component fontSize="inherit" titleAccess={name} {...props} sx={sx} />;
};

export default Icon;
