/* eslint-disable @typescript-eslint/no-explicit-any */
import { ClientError } from 'graphql-request';

/**
 * Type guard for {@link ClientError}
 * */
export const isClientError = (x: any): x is ClientError => {
  return x && 'request' in x && 'response' in x && 'data' in x.response && 'errors' in x.response
    ? true
    : false;
};

/**
 * Check and parse a JSON {@link ClientError}
 * */
export const parseApiError = (maybeError: any): ClientError | null => {
  try {
    const maybeClientError = typeof maybeError === 'string' ? JSON.parse(maybeError) : maybeError;
    const error = isClientError(maybeClientError) ? maybeClientError : null;
    return error;
  } catch (error) {
    console.warn(error);
  }
  return null;
};
