import { pxToRem } from './common';

import { Breakpoint, breakpoints } from '@/theme/config/breakpoints';

type ValueClampProps = [
  minSizePx: number,
  maxSizePx: number,
  breakpointMin?: number | Breakpoint,
  breakpointMax?: number | Breakpoint
];

export default function cssFluidSize(props: ValueClampProps): string {
  const [minSizePx, maxSizePx, breakpointMin = 'md', breakpointMax = 'xl'] = props;

  const breakpointMinPx =
    typeof breakpointMin === 'number' ? breakpointMin : breakpoints[breakpointMin];
  const breakpointMaxPx =
    typeof breakpointMax === 'number' ? breakpointMax : breakpoints[breakpointMax];

  const diff = maxSizePx - minSizePx;
  const screenDiff = breakpointMaxPx - breakpointMinPx;

  const slope = diff / screenDiff;

  if (minSizePx === maxSizePx || slope <= 0) return pxToRem(minSizePx);

  const slopePercentage = slope * 100;
  const slopeU = `${slopePercentage.toFixed(3)}vw`;

  const yIntersection = Number((-breakpointMinPx * slope + minSizePx).toFixed(3));

  const calcStr = `calc(${pxToRem(yIntersection)} + ${slopeU})`;

  return `clamp(${pxToRem(minSizePx)}, ${calcStr}, ${pxToRem(maxSizePx)})`;
}
